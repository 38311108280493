import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BackToTop from "../../components/back-to-top"
import { Container, Row, Col } from "reactstrap"
import { LazyLoadImage } from "react-lazy-load-image-component"

const NicCabano = () => {
  return (
    <Layout>
      <Seo title="Doctor Nic Cabano, DVM" />
      <div className="hero-adjustment pb-0">
        <div className="container">
          <div className="hero-content-wrapper">
            <div className="row no-gutters">
              <div className="col-12 col-md-7">
                <div className="hero-dog-bg">
                  <div className="dog-bg"></div>
                </div>
              </div>
              <div className="col-12 col-md-5">
                <div className="hero-video">
                  <div>
                    <div>
                      <div className="video-modal-btn no-video">
                        <LazyLoadImage
                          effect="blur"
                          src="/assets/img/tplo-video.jpg"
                          alt="video"
                          className="video-img img-fluid v-h"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-only-heading inner-page-title text-right arthroscopy-hero no-videoHero">
              <h1>
                Meet <br />
                Dr. Nic
                <br />
                Cabano
              </h1>
            </div>
          </div>
        </div>
      </div>
      <Container className="acl-container mb-5 mt-5 mt-md-0">
        <div className="doctor-body">
          <Row className="border-none">
            <Col md={4} className="img-resp">
              <LazyLoadImage
                effect="blur"
                src="/assets/img/doctor-nic-cabano.jpg"
                alt="Doctor"
                className="img-fluid mb-4"
              />
            </Col>
            <Col md={8}>
              <p>
                Dr. Nic R. Cabano joins Colorado Canine Orthopedics as Medical Director and
                Associate Surgeon, following his retirement from the United States Army in 2024.
                He was born and raised in the San Francisco Bay Area and graduated from the
                University of California Davis, School of Veterinary Medicine. He completed his
                surgical residency at Colorado State University and serves as adjunct faculty
                for the Penn Vet Working Dog Center.
              </p>
              <p>
                Considered a subject matter expert on working dog clinical operations,
                Dr. Cabano’s commitment to the canine tactical athlete has shaped his military
                and professional career, with key appointments as Director for the Department of
                Defense Military Working Dog Veterinary Service and Attending Veterinarian for
                the US Army Medical Department Center and School in San Antonio, Texas. His
                passion for working dog wellness led to his becoming a Diplomate of the American
                College of Veterinary Surgeons in 2013, and the American College of Veterinary
                Sports Medicine and Rehabilitation in 2017.
              </p>
              <p>
                As a surgeon and rehabilitation specialist, Dr. Cabano is experienced in
                shepherding a variety of patients through advanced recovery programs involving
                orthopedic and neurologic conditions (surgical and non-surgical). He is committed
                to safeguarding animal welfare and the human-animal bond with each patient
                encounter. His enduring interests center on canine athletes and maximizing
                outcomes through conditioning for injury prevention and enhanced mission
                capabilities, trauma and wound healing, and management of orthopedic conditions
                (particularly stifle and lumbosacral diseases). He has authored numerous
                peer-reviewed journal publications and book chapters, and is an internationally
                recognized speaker on canine clinical care.
              </p>
              <p>
                The Cabano family is thrilled to return to Colorado as their forever home.
                His partner Emily is a sport and clinical psychologist in the region; and their
                doggy daughters, Luna and Minerva, offer endless mischief to manage. When not
                spending time training in the gym, the Cabano family enjoys outdoor adventures
                and exploring the local culinary scene.
              </p>
            </Col>
          </Row>
        </div>
      </Container>
      <BackToTop />
    </Layout>
  )
}

export default NicCabano
